export const USER_LOGIN = "USER_LOGIN";
export const USER_LOGOUT = "USER_LOGOUT";
export const FORGET_PASSWORD = "FORGET_PASSWORD";
export const OTP_VERIFICATION = "OTP_VERIFICATION";
export const VERIFY_TOKEN = "VERIFY_TOKEN";
// invite people
export const SEND_INVITE_PEOPLE = "SEND_INVITE_PEOPLE";

// manage users
export const GET_ALL_USERS = "GET_ALL_USERS";
export const GET_FILTERED_USERS = "GET_FILTERED_USERS";
export const GET_DETAIL_DATA = "GET_DETAIL_DATA";
export const GET_SCORE_DATA = "GET_SCORE_DATA";
export const GET_GOALS_DATA = "GET_GOALS_DATA";
export const UPDATE_USER = "UPDATE_USER";
export const ADD_USER = "ADD_USER";
export const SURVEY_RESULT = "SURVEY_RESULT";
export const RECOVER_STREAK = "RECOVER_STREAK";
// journey
export const GET_JOURNEYS_DATA = "GET_JOURNEYS_DATA";
// manage coach
export const GET_ALL_COACHES = "GET_ALL_COACHES";
export const UPDATE_COACH = "UPDATE_COACH";
export const ADD_COACH = "ADD_COACH";

// dashboard data
export const GET_DASHBOARD_DATA = "GET_DASHBOARD_DATA";

// assign the coach

export const ASSIGN_COACH = "ASSIGN_COACH";
// block user
export const BLOCK_USER = "BLOCK_USER";
export const BLOCK_USERS_LIST = "BLOCK_USERS_LIST";
export const UNBLOCK_USER = "UNBLOCK_USER";
//block coach
export const BLOCK_COACH = "BLOCK_COACH";
export const BLOCK_COACHES_LIST = "BLOCK_COACHES_LIST";
export const UNBLOCK_COACH = "UNBLOCK_COACH";

// activities
export const GET_ALL_USER_ACTIVITES = "GET_ALL_USER_ACTIVITES";
export const GET_SELECTED_ACTIVITY_DATA_OF_USER =
  "GET_SELECTED_ACTIVITY_DATA_OF_USER";
export const CREATE_ACTIVITY = "CREATE_ACTIVITY";
export const GET_ACTIVITY = "GET_ACTIVITY";
export const DELETE_ACTIVITY = "DELETE_ACTIVITY";
export const UPDATE_ACTIVITY = "UPDATE_ACTIVITY";
// user goals

export const GET_USER_GOALS = "GET_USER_GOALS";
// Questions
export const ADD_QUESTION = "ADD_QUESTION";
export const ALL_QUESTIONS = "ALL_QUESTIONS";
export const GET_MATRIX = "GET_MATRIX";
export const GET_QUESTION_BY_ID = "GET_QUESTION_BY_ID";
export const DELETE_QUESTION = "DELETE_QUESTION";
export const UPDATE_QUESTION = "UPDATE_QUESTION";
export const GET_QUESTION_POSITIONS = "GET_QUESTION_POSITIONS";
// Organization
export const ALL_ORGANIZATIONS = "ALL_ORGANIZATIONS";
export const ADD_ORGANIZATION = "ADD_ORGANIZATION";
export const BLOCK_ORGANIZATION = "BLOCK_ORGANIZATION";
export const BLOCK_ORGANIZATIONS_LIST = "BLOCK_ORGANIZATIONS_LIST";
export const UNBLOCK_ORGANIZATION = "UNBLOCK_ORGANIZATION";
export const UPDATE_ORGANIZATION = "UPDATE_ORGANIZATION";
//Upload content
export const UPLOAD_FILE = "UPLOAD_FILE";
export const GET_CONTENT = "GET_CONTENT";
export const UPLOAD_CONTENT = "UPLOAD_CONTENT";
export const DAILY_CONTENT = "DAILY_CONTENT";
export const CONTENTLIST_BY_PLAN = "CONTENTLIST_BY_PLAN";
export const UPLOAD_CSV = "UPLOAD_CSV";
export const DELETE_CONTENT = "DELETE_CONTENT";
export const UPDATE_CONTENT = "UPDATE_CONTENT";

//GOALS
export const ALL_GOALS = "ALL_GOALS";
// Plans
export const ALL_PLANS = "ALL_PLANS";
export const FILTERED_PLANS = "ALL_FILTERED";
export const ALL_FREE_PLANS = "ALL_FREE_PLANS";
export const ADD_PLAN = "ADD_PLAN";
export const DELETE_PLAN = "DELETE_PLAN";
export const UPDATE_PLAN = "UPDATE_PLAN";
export const GET_PLAN = "GET_PLAN";
export const PLAN_DAY_LIST = "PLAN_DAY_LIST";
//report
export const GET_COACH_REPORT = "GET_COACH_REPORT";
export const GET_ORGANIZATION_REPORT = "GET_ORGANIZATION_REPORT";
export const GET_USERS_REPORT = "GET_USERS_REPORT";
export const GET_RANGE_STREAKS_REPORT = "GET_RANGE_STREAKS_REPORT";
//feedbacks
export const GET_FEEDBACKS = "GET_FEEDBACKS";
// contests
export const GET_ALL_CONTESTS = "GET_ALL_CONTESTS";
export const GET_ORGANIZATIONS_CONTESTS = "GET_ORGANIZATIONS_CONTESTS";
export const POST_CONTEST = "POST_CONTEST";
export const PATCH_CONTEST = "PATCH_CONTEST";
export const DELETE_CONTEST = "DELETE_CONTEST";
