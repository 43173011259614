import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Card,
  Dropdown,
  Form,
  Modal,
  NavLink,
  Spinner,
} from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import { ClipLoader } from "react-spinners";
import Select from "react-select";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import ImageResize from "quill-image-resize-module-react";
import ImageCompress from "quill-image-compress";
import { useDispatch, useSelector } from "react-redux";
import {
  contentListByPlan,
  deleteContent,
  getAllFreePlans,
  getAllGoals,
  getAllPlans,
  getContent,
  getDailyContent,
  planDayList,
  updateContent,
  uploadContentData,
  uploadCSV,
  onUploadFile,
} from "../../redux/actions/authAction";
import { toastify } from "../../components/Toast";
import moment from "moment";
import { Oval } from "react-loader-spinner";
const UploadContent = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllGoals());
    dispatch(getAllFreePlans());
    dispatch(getAllPlans());
  }, []);
  const {
    contentList,
    DailyContentData,
    planDayListData,
    allGoalsData,
    selectedContentData,
    uploadFileData,
  } = useSelector((state) => state.getUploadContentData);
  const contentDaysArray = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
    41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59,
    60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78,
    79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90,
  ];
  const [contentValue, setContentValue] = useState({});
  const [selectedGoal, setSelectedGoal] = useState("");
  const [contentTitle, setContentTitle] = useState("");
  const [contentDay, setContentDay] = useState("");
  const [uploadContentModal, setUploadContentModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [selectedResource, setSelectedResource] = useState(null);
  const [selectedGoalForUploadContent, setSelectedGoalForUploadContent] =
    useState(null);
  const [selectedGoalForEditContent, setSelectedGoalForEditContent] =
    useState("");
  const [goalDropDownObj, setGoalDropDownObj] = useState(null);
  const [resourceObj, setResourceObj] = useState({});
  const [descriptionState, setDescriptionState] = useState("");
  const [uploadCSVModal, setUploadCSVModal] = useState(false);
  const [selectedCSVFile, setSelectedCSVFile] = useState("");
  const [editContentTitle, setEditContentTitle] = useState("");
  const [editContentCategory, setEditContentCategory] = useState("");
  const [editContentResource, setEditContentResource] = useState("");
  const [editContentDay, setEditContentDay] = useState("");
  const [editContentModal, setEditContentModal] = useState(false);
  const [viewContentModal, setViewContentModal] = useState(false);
  const [loaderForViewContentModal, setLoaderForViewContentModal] =
    useState(false);
  const [editDescription, setEditDescription] = useState("");
  const [editContentValue, setEditContentValue] = useState({});
  const [editContentId, setEditContentId] = useState("");
  const [MP4LinkModal, setMP4LinkModal] = useState(false);
  const [MP4Link, setMP4Link] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [MP4Video, setMP4Video] = useState(null);
  const [selectedGoals, setSelectedGoals] = useState([]);
  const [loadingForContent, setLoadingForContent] = useState(false);
  const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false);

  useEffect(() => {
    setLoadingForContent(true);
    dispatch(
      contentListByPlan(allGoalsData?.data?.[0]?._id, setLoadingForContent)
    );
  }, []);
  useEffect(() => {
    if (selectedGoalForUploadContent) {
      dispatch(planDayList(selectedGoalForUploadContent));
    }
  }, [selectedGoalForUploadContent]);

  const onUploadContentData = () => {
    if (selectedCategory === "Awareness") {
      if (contentDay) {
        const awarenessObj = {
          title: contentTitle,
          goalId: selectedGoalForUploadContent,
          category: selectedCategory,
          dayOfContent: contentDay,
          content: contentValue,
        };
        dispatch(uploadContentData(awarenessObj, callBackForUploadContent));
      } else {
        toastify("error", "Please add content's day");
      }
    } else if (selectedCategory === "Activity") {
      if (contentDay) {
        const activityObj = {
          title: contentTitle,
          goalId: selectedGoalForUploadContent,
          category: selectedCategory,
          dayOfContent: contentDay,
          description: descriptionState,
        };
        dispatch(uploadContentData(activityObj, callBackForUploadContent));
      } else {
        toastify("error", "Please add content's day");
      }
    } else {
      toastify("error", "Please select category");
    }
  };

  const callBackForUploadContent = (res) => {
    if (res) {
      if (selectedGoalForUploadContent) {
        dispatch(contentListByPlan(selectedGoalForUploadContent));
      }

      setUploadContentModal(false);
      onRemoveAllContentData();
    }
  };
  const onRemoveAllContentData = () => {
    setSelectedGoalForUploadContent("");
    setContentValue("");
    setContentTitle("");
    setSelectedCategory("");
    setDescriptionState("");
    setResourceObj("");
    setContentDay("");
  };
  Quill.register("modules/ImageResize", ImageResize);
  Quill.register("modules/imageCompress", ImageCompress);
  const editorModules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }],

      ["bold", "italic", "underline", "strike"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
    ],
    ImageResize: {
      parchment: Quill.import("parchment"),
      handleStyles: {
        displaySize: true,
        backgroundColor: "black",
        border: "none",
        color: "white",
      },
      modules: ["Resize", "DisplaySize"],
    },
    imageCompress: {
      quality: 0.7, // default
      maxWidth: 700,
      maxHeight: 700,
      imageType: ["image/jpeg", "image/png", "image/jpg"], // default
      debug: true, // default
    },
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };
  const editorFormats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
  ];
  //upload content
  const onUploadContentModalClose = () => {
    setUploadContentModal(false);
    onRemoveAllContentData();
  };

  const goalsHandler = (id) => {
    setLoadingForContent(true);
    if (id.match(/\d+/g)) {
      setSelectedResource(id);
      const goalFound = allGoalsData?.data?.find((goal) => goal._id === id);
      setGoalDropDownObj(goalFound);
      dispatch(contentListByPlan(goalFound?._id, setLoadingForContent));
    }
  };

  const handleGoalSelection = (selectedObj) => {
    // setLoading(true)
    // console.log("Sele0099009", selectedObj);
    const selectedIds = selectedObj.map((goal) => goal.value);
    const selectedGoals = allGoalsData?.data?.find(
      (goal) => goal._id === selectedIds
    );
    console.log("isdddd", selectedIds);
    dispatch(contentListByPlan(selectedIds));
    console.log(
      "Content List Dispatch",
      dispatch(contentListByPlan(selectedIds))
    );
    setSelectedGoals(selectedGoals);
    setGoalDropDownObj(selectedIds);
    // setLoading(false)
  };

  const goalOptions = allGoalsData?.data?.map((data) => ({
    value: data?._id,
    label: data?.title,
  }));

  const GoalForUploadContentHandler = (goalId) => {
    if (goalId.match(/\d+/g)) {
      setSelectedGoalForUploadContent(goalId);
      // allPlans?.data?.find((plan) => plan?._id === resourceId);

      // setResourceObj(allPlans?.data?.find((plan) => plan?._id === resourceId));
    }
  };
  const onUploadCSVModalClose = () => {
    setSelectedCSVFile("");
    setSelectedGoal("");
    setUploadCSVModal(false);
  };
  const onUploadCSVFile = () => {
    if (selectedCSVFile && selectedGoal) {
      const formData = new FormData();
      formData.append("csv", selectedCSVFile);
      formData.append("goalId", selectedGoal);
      dispatch(uploadCSV(formData, callBackForCSVUpload));
    } else {
      toastify("error", "Goal is mandatory");
    }
  };
  const callBackForCSVUpload = () => {
    onUploadCSVModalClose();
  };
  const deleteContentHandler = (contentData) => {
    // dispatch(deleteContent(contentData?._id, callBackForDelete));
    setDeleteConfirmationModal(true);
  };
  const onDeleteConfirmationModalClose = () => {
    setDeleteConfirmationModal(false);
  };
  const callBackForDelete = (res, id) => {
    setLoadingForContent(true);
    dispatch(contentListByPlan(goalDropDownObj._id, setLoadingForContent));
  };
  const editContentHandler = (data) => {
    dispatch(getContent(data._id, callbackForEditContent));
    setEditContentModal(true);
    setEditContentTitle(data?.title);
    setEditContentCategory(data?.category);
    setEditContentResource(data?.planId?.name);
    setEditContentDay(data?.dayOfContent);
  };
  const viewContentHandler = (data) => {
    dispatch(getContent(data._id, callbackForEditContent));
    setViewContentModal(true);
    setLoaderForViewContentModal(true);
    setEditContentTitle(data?.title);
    setEditContentCategory(data?.category);
    setEditContentResource(data?.planId?.name);
    setEditContentDay(data?.dayOfContent);
  };
  const callbackForEditContent = (res) => {
    setLoaderForViewContentModal(false);
    setEditDescription(res?.data?.content);
    setEditContentValue(res?.data?.content);
    setEditContentId(res?.data?._id);
    setSelectedGoalForEditContent(res?.data?.planId?.goalId);
  };
  const onEditContentModalClose = () => {
    setEditContentModal(false);
  };
  const onViewContentModalClose = () => {
    setViewContentModal(false);
  };
  const onEditContentDataSave = () => {
    if (editContentCategory === "Awareness") {
      if (editContentDay) {
        const awarenessObj = {
          title: editContentTitle,
          category: editContentCategory,
          dayOfContent: editContentDay,
          content: editContentValue,
          contentId: editContentId,
        };
        dispatch(updateContent(awarenessObj, callBackForEdit));
      } else {
        toastify("error", "Please add content's day");
      }
    } else if (editContentCategory === "Activity") {
      if (editContentDay) {
        const activityObj = {
          title: editContentTitle,
          category: editContentCategory,
          dayOfContent: editContentDay,
          content: editDescription,
          contentId: editContentId,
        };

        dispatch(updateContent(activityObj, callBackForEdit));
      } else {
        toastify("error", "Please add content's day");
      }
    } else {
      toastify("error", "Please select category");
    }
  };
  const callBackForEdit = () => {
    setEditContentModal(false);
    dispatch(contentListByPlan(selectedGoalForEditContent));
  };
  const onUploadMP4File = () => {
    if (MP4Video) {
      const formData = new FormData();
      formData.append("file", MP4Video);
      dispatch(onUploadFile(formData, callbackForMP4Upload));
      setIsLoading(true);
    } else {
      toastify("error", "Kindly select MP4 video");
    }
  };
  const callbackForMP4Upload = (res) => {
    if (res) {
      setMP4LinkModal(true);
      setMP4Link(uploadFileData?.data);
      setIsLoading(false);
    }
  };
  const onMP4ModalClose = () => {
    setMP4LinkModal(false);
  };
  const onCopyFileUrl = () => {
    navigator?.clipboard?.writeText(MP4Link);
    toastify("success", "URL copied successfully");

    onMP4ModalClose();
  };

  const customStyles = {
    menu: (provided) => ({
      ...provided,
      position: "absolute",
      zIndex: 9999, // Set a high z-index to make sure it appears above other elements
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: "200px", // Adjust the max height of the dropdown if needed
      overflow: "auto", // Enable scrolling if the options exceed the max height
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#007BFF" : "white",
      color: state.isSelected ? "white" : "black",
      ":hover": {
        backgroundColor: "#007BFF",
        color: "white",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      background: "transparent !important", // Set the background color to transparent
      border: "none !important", // Remove the border
    }),
  };

  return (
    <>
      {" "}
      <>
        <ToastContainer />
      </>
      {/* MP4 video modal */}
      <>
        <Modal
          show={MP4LinkModal}
          onHide={onMP4ModalClose}
          backdrop="static"
          keyboard={false}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>MP4 video link</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex">
              <Form.Control
                className="question-text-input"
                type="text"
                value={MP4Link}
              />
              <Button
                className="ml-3"
                variant="success"
                onClick={onCopyFileUrl}
              >
                Copy
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </>
      {/* {Upload content} */}
      <>
        <Modal
          backdrop="static"
          size="lg"
          className="p-0"
          keyboard={false}
          data-dismiss="modal"
          show={uploadContentModal}
          onHide={onUploadContentModalClose}
          centered
        >
          <Modal.Header className="modal-header-workout" closeButton>
            <Modal.Title>Upload Content</Modal.Title>
          </Modal.Header>
          <Modal.Body className="upload-content-modal-body">
            <>
              <div className="content-title">
                <Form.Group
                  className="question-text mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="d-flex ">
                    <h5>Title</h5>
                  </Form.Label>
                  <Form.Control
                    className="question-text-input"
                    type="text"
                    placeholder="Enter content title"
                    onChange={(e) => setContentTitle(e.target.value)}
                    value={contentTitle}
                  />
                </Form.Group>
              </div>

              <div className="goal-for-content mb-3">
                <Form.Label className="d-flex ">
                  <h5>Goals</h5>
                </Form.Label>
                <Form.Select
                  onChange={(e) => GoalForUploadContentHandler(e.target.value)}
                  // value={selectedGoalForUploadContent}
                  aria-label="Default select example"
                >
                  <option hidden>Select goals</option>
                  {allGoalsData?.data?.map((goal, index) => {
                    return <option value={goal?._id}>{goal?.title}</option>;
                  })}
                </Form.Select>
              </div>

              <>
                {selectedGoalForUploadContent ? (
                  <div className="goal-for-content mb-3">
                    <Form.Label className="d-flex ">
                      <h5>Content's day</h5>
                    </Form.Label>
                    <Form.Select
                      onChange={(e) => setContentDay(e.target.value)}
                      value={contentDay}
                      aria-label="Default select example"
                    >
                      <option hidden>Select content's day</option>
                      {planDayListData?.data?.map((day, index) => {
                        return <option value={day}>{day}</option>;
                      })}
                    </Form.Select>
                  </div>
                ) : (
                  <div className="content-day">
                    <Form.Group
                      className="question-text mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label className="d-flex ">
                        <h5>Content's day</h5>
                      </Form.Label>
                      <Form.Control className="question-text-input" disabled />
                    </Form.Group>
                  </div>
                )}

                <div className="goal-for-content mb-3">
                  <Form.Label className="d-flex ">
                    <h5>Categories</h5>
                  </Form.Label>
                  <Form.Select
                    onChange={(e) => setSelectedCategory(e.target.value)}
                    value={selectedCategory}
                    aria-label="Default select example"
                  >
                    <option hidden>Select category</option>
                    <option>Awareness </option>
                    <option>Activity</option>
                    {/* <option>Resource</option> */}
                  </Form.Select>
                </div>
                {selectedCategory === "Awareness" ? (
                  <>
                    <div className="React-quill-container">
                      <Form.Group
                        className="mp4-input mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label className="d-flex ">
                          <h5>Add a MP4 video</h5>
                        </Form.Label>
                        <div className="d-flex">
                          <Form.Control
                            className="question-text-input"
                            type="file"
                            name="file"
                            placeholder="Enter mp4 video"
                            accept="video/mp4,video/x-m4v,video/*"
                            onChange={(e) => {
                              setMP4Video(e.target.files[0]);
                            }}
                          />
                          {isLoading ? (
                            <Button
                              className="ml-3 pr-4 pl-4"
                              variant="primary"
                            >
                              <Spinner
                                className=""
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                            </Button>
                          ) : (
                            <Button
                              className="ml-3"
                              variant="primary"
                              onClick={onUploadMP4File}
                            >
                              Upload
                            </Button>
                          )}
                        </div>
                      </Form.Group>

                      <Form.Label className="d-flex ">
                        <h5>Add content</h5>
                      </Form.Label>

                      <ReactQuill
                        theme="snow"
                        value={contentValue}
                        onChange={setContentValue}
                        modules={editorModules}
                        formats={editorFormats}
                      />
                    </div>
                  </>
                ) : null}
                {selectedCategory === "Activity" ? (
                  <>
                    <div className="activity-upload-container">
                      {/* <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label className="mt-3"> 
                          <h5>Description</h5>
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={4}
                          placeholder="write a description for your activity"
                          onChange={(e) => setDescriptionState(e.target.value)}
                          value={descriptionState}
                        />
                      </Form.Group> */}
                      <Form.Label className="d-flex ">
                        <h5>Add content</h5>
                      </Form.Label>

                      <ReactQuill
                        theme="snow"
                        value={descriptionState}
                        onChange={setDescriptionState}
                        modules={editorModules}
                        formats={editorFormats}
                      />
                    </div>
                  </>
                ) : null}
              </>
            </>
          </Modal.Body>
          <Modal.Footer>
            <div className="upload-buttons mt-3">
              {" "}
              <Button variant="secondary" onClick={onUploadContentModalClose}>
                Cancel
              </Button>
              <Button
                className="ml-3"
                variant="primary"
                onClick={onUploadContentData}
              >
                Save
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </>
      {/* {Edit Upload content} */}
      <>
        <Modal
          backdrop="static"
          size="lg"
          className="p-0"
          keyboard={false}
          data-dismiss="modal"
          show={editContentModal}
          onHide={onEditContentModalClose}
          centered
        >
          <Modal.Header className="modal-header-workout" closeButton>
            <Modal.Title>Edit Content</Modal.Title>
          </Modal.Header>
          <Modal.Body className="upload-content-modal-body">
            <>
              <div className="content-title">
                <Form.Group
                  className="question-text mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="d-flex ">
                    <h5>Title</h5>
                  </Form.Label>
                  <Form.Control
                    className="question-text-input"
                    type="text"
                    placeholder="Enter content title"
                    onChange={(e) => setEditContentTitle(e.target.value)}
                    value={editContentTitle}
                  />
                </Form.Group>
              </div>

              <>
                <div className="goal-for-content mb-3">
                  <Form.Label className="d-flex ">
                    <h5>Content's day</h5>
                  </Form.Label>
                  <Form.Select
                    onChange={(e) => setEditContentDay(e.target.value)}
                    value={editContentDay}
                    aria-label="Default select example"
                  >
                    <option hidden>Select content's day</option>
                    {contentDaysArray.map((day, index) => {
                      return <option value={day}>{day}</option>;
                    })}
                  </Form.Select>
                </div>

                <div className="goal-for-content mb-3">
                  <Form.Label className="d-flex ">
                    <h5>Categories</h5>
                  </Form.Label>
                  <Form.Select
                    onChange={(e) => setEditContentCategory(e.target.value)}
                    value={editContentCategory}
                    aria-label="Default select example"
                  >
                    <option hidden>Select category</option>
                    <option>Awareness </option>
                    <option>Activity</option>
                    {/* <option>Resource</option> */}
                  </Form.Select>
                </div>
                {editContentCategory === "Awareness" ? (
                  <>
                    <div className="React-quill-container">
                      <Form.Group
                        className="mp4-input mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label className="d-flex ">
                          <h5>Add a MP4 video</h5>
                        </Form.Label>
                        <div className="d-flex">
                          <Form.Control
                            className="question-text-input"
                            type="file"
                            name="file"
                            placeholder="Enter mp4 video"
                            accept="video/mp4,video/x-m4v,video/*"
                            onChange={(e) => {
                              setMP4Video(e.target.files[0]);
                            }}
                          />
                          {isLoading ? (
                            <Button
                              className="ml-3 pr-4 pl-4"
                              variant="primary"
                            >
                              <Spinner
                                className=""
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                            </Button>
                          ) : (
                            <Button
                              className="ml-3"
                              variant="primary"
                              onClick={onUploadMP4File}
                            >
                              Upload
                            </Button>
                          )}
                        </div>
                      </Form.Group>
                      <Form.Label className="d-flex ">
                        <h5>Add content</h5>
                      </Form.Label>
                      <ReactQuill
                        theme="snow"
                        value={editContentValue}
                        onChange={setEditContentValue}
                        modules={editorModules}
                        formats={editorFormats}
                      />
                    </div>
                  </>
                ) : null}
                {editContentCategory === "Activity" ? (
                  <>
                    <div className="activity-upload-container">
                      {/* <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label className="mt-3">
                          <h5>Description</h5>
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={4}
                          placeholder="write a description for your activity"
                          onChange={(e) => setEditDescription(e.target.value)}
                          value={editDescription}
                        />
                      </Form.Group> */}
                      <Form.Label className="d-flex ">
                        <h5>Add content</h5>
                      </Form.Label>

                      <ReactQuill
                        theme="snow"
                        value={editDescription}
                        onChange={setEditDescription}
                        modules={editorModules}
                        formats={editorFormats}
                      />
                    </div>
                  </>
                ) : null}
              </>
            </>
          </Modal.Body>
          <Modal.Footer>
            <div className="upload-buttons mt-3">
              {" "}
              <Button variant="secondary" onClick={onEditContentModalClose}>
                Cancel
              </Button>
              <Button
                className="ml-3"
                variant="primary"
                onClick={onEditContentDataSave}
              >
                Save
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </>
      {/* {View Uploaded content} */}
      <>
        <Modal
          backdrop="static"
          size="lg"
          className="p-0"
          keyboard={false}
          data-dismiss="modal"
          show={viewContentModal}
          onHide={onViewContentModalClose}
          centered
        >
          <Modal.Header className="modal-header-workout" closeButton>
            <Modal.Title>View Content</Modal.Title>
          </Modal.Header>
          <Modal.Body className="upload-content-modal-body">
            {loaderForViewContentModal ? (
               <div className="view-modal-loader-container">
               {" "}
               <Oval
                 height={40}
                 width={120}
                 color="#1b46b4"
                 wrapperStyle={{}}
                 wrapperClass=""
                 visible={true}
                 ariaLabel="oval-loading"
                 secondaryColor="#56bcb2"
                 strokeWidth={6}
                 strokeWidthSecondary={6}
               />
             </div>
            ) : (
              <>
                <div className="content-title">
                  <Form.Group
                    className="question-text mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label className="d-flex ">
                      <h5>Title</h5>
                    </Form.Label>
                    <Form.Control
                      disabled
                      className="question-text-input"
                      type="text"
                      placeholder="Enter content title"
                      onChange={(e) => setEditContentTitle(e.target.value)}
                      value={editContentTitle}
                    />
                  </Form.Group>
                </div>

                <>
                  <div className="goal-for-content mb-3">
                    <Form.Label className="d-flex ">
                      <h5>Content's day</h5>
                    </Form.Label>
                    <Form.Select
                      disabled
                      onChange={(e) => setEditContentDay(e.target.value)}
                      value={editContentDay}
                      aria-label="Default select example"
                    >
                      <option hidden>Select content's day</option>
                      {contentDaysArray.map((day, index) => {
                        return <option value={day}>{day}</option>;
                      })}
                    </Form.Select>
                  </div>

                  <div className="goal-for-content mb-3">
                    <Form.Label className="d-flex ">
                      <h5>Categories</h5>
                    </Form.Label>
                    <Form.Select
                      disabled
                      onChange={(e) => setEditContentCategory(e.target.value)}
                      value={editContentCategory}
                      aria-label="Default select example"
                    >
                      <option hidden>Select category</option>
                      <option>Awareness </option>
                      <option>Activity</option>
                      {/* <option>Resource</option> */}
                    </Form.Select>
                  </div>
                  {editContentCategory === "Awareness" ? (
                    <>
                      <div className="React-quill-container">
                        <Form.Label className="d-flex ">
                          <h5>Current content</h5>
                        </Form.Label>
                        <ReactQuill
                          theme="snow"
                          value={editContentValue}
                          onChange={setEditContentValue}
                          // modules={editorModules}
                          formats={editorFormats}
                          readOnly={true}
                        />
                      </div>
                    </>
                  ) : null}
                  {editContentCategory === "Activity" ? (
                    <>
                      <div className="activity-upload-container">
                        {/* <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label className="mt-3">
                          <h5>Description</h5>
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={4}
                          placeholder="write a description for your activity"
                          onChange={(e) => setEditDescription(e.target.value)}
                          value={editDescription}
                        />
                      </Form.Group> */}
                        <Form.Label className="d-flex ">
                          <h5>Current Activity</h5>
                        </Form.Label>

                        <ReactQuill
                          theme="snow"
                          value={editDescription}
                          onChange={setEditDescription}
                          modules={editorModules}
                          formats={editorFormats}
                          readOnly={true}
                        />
                      </div>
                    </>
                  ) : null}
                </>
              </>
            )}
          </Modal.Body>
        </Modal>
      </>
      {/* Upload CSV */}
      <>
        <Modal
          backdrop="static"
          size="lg"
          className="p-0"
          keyboard={false}
          data-dismiss="modal"
          show={uploadCSVModal}
          onHide={onUploadCSVModalClose}
          centered
        >
          <Modal.Header className="modal-header-workout" closeButton>
            <Modal.Title>Upload CSV</Modal.Title>
          </Modal.Header>
          <Modal.Body className="upload-content-modal-body">
            <>
              <div className="goal-for-content mb-3">
                <Form.Label className="d-flex ">
                  <h5>Goals</h5>
                </Form.Label>
                <Form.Select
                  onChange={(e) => setSelectedGoal(e.target.value)}
                  value={selectedGoal}
                  aria-label="Default select example"
                >
                  <option hidden>Select goal</option>
                  {allGoalsData?.data?.map((goal, index) => {
                    return <option value={goal?._id}>{goal?.title}</option>;
                  })}
                </Form.Select>
              </div>
              <div className="goal-for-content mb-3">
                <Form.Label className="d-flex ">
                  <h5>Add a .csv file :</h5>
                </Form.Label>

                <input
                  id="fileSelect"
                  type="file"
                  accept=".csv"
                  onChange={(e) => setSelectedCSVFile(e.target.files[0])}
                />
              </div>
            </>
          </Modal.Body>
          <Modal.Footer>
            <div className="upload-buttons mt-3">
              {" "}
              <Button variant="secondary" onClick={onUploadCSVModalClose}>
                Cancel
              </Button>
              <Button
                className="ml-3"
                variant="primary"
                onClick={onUploadCSVFile}
              >
                Upload
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </>
      {/* DELETE CONFIRMATION */}
      <>
        <Modal
          backdrop="static"
          className="p-0"
          keyboard={false}
          data-dismiss="modal"
          show={deleteConfirmationModal}
          onHide={onDeleteConfirmationModalClose}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h6>Are you sure you want to delete this question?</h6>
          </Modal.Body>
          <Modal.Footer>
            {/* <Button onClick={onDeleteConfirmationModalClose}>Close</Button> */}
            <Button
              variant="danger"
              onClick={() => {
                toastify("error", "This feature is temporarily blocked");
              }}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </>
      <div className="manage-user">
        <div className="row mb-3 pt-5 pl-5">
          <div className="col-md-6">
            <h1 className="healthKitUpdateHeaderFont d-flex">Manage Content</h1>
          </div>
          <div
            className="col-md-6 d-flex justify-content-end"
            style={{ paddingRight: "65px" }}
          >
            <div>
              <button
                className="btn-coach me-4"
                onClick={() =>
                  setUploadContentModal(true)
                  // toastify("error", "This feature is temporarily blocked.")
                }
              >
                Upload Content
              </button>
            </div>
            <div>
              <button
                className="btn-coach btn-block me-5"
                onClick={() =>
                  setUploadCSVModal(true)

                  // toastify("error", "This feature is temporarily blocked.")
                }
              >
                Upload CSV
              </button>
            </div>
          </div>
        </div>
        <>
          <div
            className="coach-clients-page "
            style={{ padding: "2% 3% 0% 3%" }}
          >
            <>
              <div className="goal-for-content mb-3">
                <Form.Label className="d-flex ">
                  <h5>Goals</h5>
                </Form.Label>
                <Form.Select
                  onChange={(e) => goalsHandler(e.target.value)}
                  aria-label="Default select example"
                >
                  {allGoalsData?.data?.map((data, index) => {
                    return <option value={data?._id}>{data?.title}</option>;
                  })}
                </Form.Select>
              </div>

              {/* <div className="goal-for-content mb-3">
                <Form.Label className="d-flex">
                  <h5>Goals</h5>
                </Form.Label>
                <Select
                  isMulti
                  onChange={handleGoalSelection}
                  options={goalOptions}
                  value={selectedGoals}
                  styles={customStyles}

                />
              </div> */}

              <div className="manage-content-main-container">
                {loadingForContent ? (
                  <div className="loader-container">
                    {" "}
                    <Oval
                      height={40}
                      width={120}
                      color="#1b46b4"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                      ariaLabel="oval-loading"
                      secondaryColor="#56bcb2"
                      strokeWidth={6}
                      strokeWidthSecondary={6}
                    />
                  </div>
                ) : contentList?.data?.length ? (
                  <>
                    <table>
                      <thead>
                        <tr
                          className="header-row"
                          style={{ margin: "20px", height: "50px" }}
                        >
                          <th
                            style={{
                              width: "400px",
                            }}
                          >
                            Title
                          </th>
                          {/* <th
                              style={{
                                width: "400px",
                                paddingLeft: "6px",
                              }}
                            >
                              Resource name
                            </th> */}
                          <th
                            style={{
                              width: "400px",
                            }}
                          >
                            Content day
                          </th>
                          <th
                            style={{
                              width: "400px",
                            }}
                          >
                            Category
                          </th>
                          <th
                            style={{
                              width: "400px",
                            }}
                          >
                            Created date
                          </th>

                          <th
                            style={{
                              borderTopRightRadius: "8px",
                              borderBottomRightRadius: "8px",
                            }}
                          ></th>
                        </tr>
                      </thead>

                      <tbody>
                        {contentList?.data?.map((data, index) => {
                          return (
                            <tr
                              className="whole-row-client"
                              key={index}
                              style={{
                                borderBottom: "1px solid #f1f5f9",
                              }}
                            >
                              <td
                                style={{
                                  wordBreak: "break-word",
                                }}
                              >
                                {data?.title}{" "}
                              </td>

                              {/* <td>
                                  <div
                                    className="d-grid gap-2 d-md-block"
                                    style={{
                                      wordBreak: "break-word",
                                    }}
                                  >
                                    {data?.planId?.name}
                                  </div>
                                </td> */}

                              <td
                                style={{
                                  paddingLeft: "12px",
                                  color: "#1b46b4",
                                  width: "160px",
                                  wordBreak: "break-word",
                                }}
                              >
                                {data?.dayOfContent}
                              </td>
                              <td
                                style={{
                                  paddingLeft: "12px",
                                  color: "#1b46b4",
                                  width: "500px",
                                  wordBreak: "break-word",
                                }}
                              >
                                {data?.category}
                              </td>
                              <td
                                style={{
                                  paddingLeft: "12px",
                                  color: "#1b46b4",
                                  width: "500px",
                                  wordBreak: "break-word",
                                }}
                              >
                                {moment(data?.createdAt).format("ll")}
                              </td>
                              <td
                                style={{
                                  width: "133px",
                                }}
                              >
                                <div className="clients-data-btn">
                                  <Dropdown>
                                    <Dropdown.Toggle id="toggle-btn-clients">
                                      <img
                                        src="/img/client-detail-btn.png"
                                        alt="btn-img"
                                        style={{ backgroundColor: "white" }}
                                      />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="dropdown-options-container">
                                      <Dropdown.Item className="dropdown-options">
                                        <NavLink
                                          className="create-activity-font"
                                          onClick={() => {
                                            viewContentHandler(data);
                                          }}
                                        >
                                          View
                                        </NavLink>
                                        <NavLink
                                          className="create-activity-font"
                                          onClick={() => {
                                            editContentHandler(data);
                                            // toastify(
                                            //   "error",
                                            //   "This feature is temporarily blocked."
                                            // );
                                          }}
                                        >
                                          Edit
                                        </NavLink>
                                        <NavLink
                                          className="create-activity-font"
                                          onClick={() => {
                                            deleteContentHandler(data);
                                          }}
                                        >
                                          Delete
                                        </NavLink>
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </>
                ) : (
                  <div className="loader-container">
                    <h4>No content </h4>
                  </div>
                )}
              </div>
            </>
          </div>
        </>
      </div>
    </>
  );
};

export default UploadContent;
