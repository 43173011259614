import React from "react";
import { NavLink } from "react-router-dom";

const Sidebar = () => {
  return (
    <>
      <div className="sidebar-outer">
        <div className="main-sidebar">
          <div className="sidebar-menu">
            <ul className="list-style-type-none">
              <li className="text-decoration-none">
                <NavLink
                  to="/dashboard"
                  className="nav-link text-decoration-none"
                  id="sidebar-dashboard"
                >
                  {/* <span className="menu-item-icon">
                <img src="/img/dashboard.png"/>/
                </span> */}
                  <span className="menu-item-name">Dashboard</span>
                </NavLink>
              </li>

              <li>
                <NavLink
                  to="/Users-list"
                  className="nav-link"
                  id="sidebar-dashboard"
                >
                  {/* <span className="menu-item-icon">
                <img src="/img/notify.png"/>
                </span> */}
                  <span className="menu-item-name">Manage Activities</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/Manage-coaches"
                  className="nav-link"
                  id="sidebar-dashboard"
                >
                  {/* <span className="menu-item-icon">
                <img src="/img/notify.png"/>
                </span> */}
                  <span className="menu-item-name">Manage Coaches</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/Manage-questions"
                  className="nav-link"
                  id="sidebar-dashboard"
                >
                  {/* <span className="menu-item-icon">
                <img src="/img/coach.png"/>
                </span> */}
                  <span className="menu-item-name">Manage Questions</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/Manage-users"
                  className="nav-link"
                  id="sidebar-dashboard"
                >
                  {/* <span className="menu-item-icon">
                <img src="/img/notify.png"/>
                </span> */}
                  <span className="menu-item-name">Manage Users</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/Manage-organizations"
                  className="nav-link"
                  id="sidebar-dashboard"
                >
                  {/* <span className="menu-item-icon">
                <img src="/img/coach.png"/>
                </span> */}
                  <span className="menu-item-name">Manage Organizations</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/Manage-resources"
                  className="nav-link"
                  id="sidebar-dashboard"
                >
                  {/* <span className="menu-item-icon">
                <img src="/img/notify.png"/>
                </span> */}
                  <span className="menu-item-name">Manage Resources</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/Manage-content"
                  className="nav-link"
                  id="sidebar-dashboard"
                >
                  {/* <span className="menu-item-icon">
                <img src="/img/notify.png"/>
                </span> */}
                  <span className="menu-item-name">Manage Contents</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/Coach-report"
                  className="nav-link"
                  id="sidebar-dashboard"
                >
                  {/* <span className="menu-item-icon">
                <img src="/img/notify.png"/>
                </span> */}
                  <span className="menu-item-name">Coaches Report</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/Organization-report"
                  className="nav-link"
                  id="sidebar-dashboard"
                >
                  {/* <span className="menu-item-icon">
                <img src="/img/notify.png"/>
                </span> */}
                  <span className="menu-item-name">Organizations Report</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/Users-report"
                  className="nav-link"
                  id="sidebar-dashboard"
                >
                  {/* <span className="menu-item-icon">
                <img src="/img/notify.png"/>
                </span> */}
                  <span className="menu-item-name">Users Report</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/Invite-users"
                  className="nav-link"
                  id="sidebar-dashboard"
                >
                  {/* <span className="menu-item-icon">
                <img src="/img/notify.png"/>
                </span> */}
                  <span className="menu-item-name">Invite Users</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/feedbacks"
                  className="nav-link"
                  id="sidebar-dashboard"
                >
                  {/* <span className="menu-item-icon">
                <img src="/img/notify.png"/>
                </span> */}
                  <span className="menu-item-name">Feedbacks</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/"
                  className="nav-link"
                  id="sidebar-dashboard"
                  // onClick={handleLogout}
                >
                  {/* <span className="menu-item-icon">
                  <img src="/img/logout.png"/>
                </span> */}
                  <span className="menu-item-name">Logout</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
