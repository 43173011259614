import React from "react";
import {
  Route,
  BrowserRouter,
  Routes,
  Navigate,
  Outlet,
} from "react-router-dom";
import "./App.css";

import "bootstrap/dist/css/bootstrap.min.css";
import { useSelector } from "react-redux";
import Login from "../src/modules/pages/Login";
import ManageUsers from "./modules/pages/ManageUsers";
import ManageQuestions from "./modules/pages/ManageQuestions";
import Layout from "./components/Layout";
import InviteUser from "./modules/pages/InviteUser";
import Dashboard from "./modules/pages/Dashboard";
import ManageActivities from "./modules/pages/ManageActivities";
import ManageContests from "./modules/pages/ManageContests";
import ManageCoaches from "./modules/pages/ManageCoaches";
import ManageActivitiesSelected from "./modules/pages/ManageActivitiesSelected";
import ManageOrganizations from "./modules/pages/ManageOrganizations";
import UsersReport from "./modules/pages/UsersReport";
import UploadContent from "./modules/pages/UploadContent";
import ManagePlans from "./modules/pages/ManagePlans";
import CoachReport from "./modules/pages/CoachReport";
import OrganizationReport from "./modules/pages/OrganizationReport";
import Feedbacks from "./modules/pages/Feedbacks";

function App() {
  const { user } = useSelector((state) => state?.authentication);
  const PublicRoute = ({ component: Component, ...rest }) => {
    // user.number? otp : servey
    // const isAuthenticated = user?.data?.token ? true : false;
    // return !isAuthenticated ? <Navigate replace to="/survey" />: <Navigate replace to="/dashboard" />;
  };

  const UserRoute = ({ component: Component, authed, ...rest }) => {
    const isAuthenticated = user?.token ? true : false;
    return isAuthenticated ? <Outlet /> : <Navigate replace to="/login" />;
  };
  // const CoachRoute = ({ component: Component, authed, ...rest }) => {
  //   const isAuthenticated = user?.data?.token ? true : false;
  //   return isAuthenticated ? <Outlet /> : <Navigate replace to="/login" />;
  // };

  return (
    <div className="App">
      <BrowserRouter>
        {/* <React.Suspense> */}
        <Routes>
          {/* <Route exact path="/" element={<PublicRoute />}> */}
          <Route exact path="/login" element={<Login />} />

          <Route exact path="/" element={<Navigate replace to="/login" />} />
          {/* </Route> */}

          <Route exact path="/" element={<UserRoute />}>
            <Route
              exact
              path="/dashboard"
              element={<Layout component={Dashboard} />}
            />
            <Route
              exact
              path="/Users-list"
              element={<Layout component={ManageActivities} />}
            />
            <Route
              exact
              path="/Manage-coaches"
              element={<Layout component={ManageCoaches} />}
            />
            <Route
              exact
              path="/Manage-contests"
              element={<Layout component={ManageContests} />}
            />
            <Route
              exact
              path="/Manage-questions"
              element={<Layout component={ManageQuestions} />}
            />
            <Route
              exact
              path="/Manage-users"
              element={<Layout component={ManageUsers} />}
            />
            <Route
              exact
              path="/Manage-organizations"
              element={<Layout component={ManageOrganizations} />}
            />
            <Route
              exact
              path="/Manage-resources"
              element={<Layout component={ManagePlans} />}
            />
            <Route
              exact
              path="/Manage-activities"
              element={<Layout component={ManageActivitiesSelected} />}
            />
            <Route
              exact
              path="/Manage-content"
              element={<Layout component={UploadContent} />}
            />
            <Route
              exact
              path="/Coach-report"
              element={<Layout component={CoachReport} />}
            />
            <Route
              exact
              path="/Organization-report"
              element={<Layout component={OrganizationReport} />}
            />{" "}
            <Route
              exact
              path="/Users-report"
              element={<Layout component={UsersReport} />}
            />   
            <Route
              exact
              path="/Invite-users"
              element={<Layout component={InviteUser} />}
            />
            <Route
              exact
              path="/feedbacks"
              element={<Layout component={Feedbacks} />}
            />
            {/* <Route path="*" element={<Page404 />} /> */}
          </Route>
        </Routes>
        {/* </React.Suspense> */}
      </BrowserRouter>
    </div>
  );
}

export default App;
