const authURLs = {
  // authentication
  login: "/user/login",
  otpSend: "/otp/send",
  otpVerify: "/otp/verify",
  forget: "/user/forget-password",
  reset: "/user/reset-password",
  verify_token: "/user/verify-token",

  // send invite people

  sendInvitePeopleUrl: "/admin/user/invite-user",

  // get all users
  getAllUsersUrl: "/admin/user/get-allUsers",

  // get filtered users
  getFilteredUsersUrl: "/admin/user/filtered-users",
  // get survey result of single user
  getSurveyResultOfUserUrl: "/admin/survey/survey-answers",
  // get all coaches
  getAllCoachesUrl: "/admin/coach/get-allCoaches",
  // dashboard
  getDashboardDataUrl: "/admin/dashboard/dashboard-stats",
  // assign a coach

  assignCoachUrl: "/admin/user/assign-coach",

  // block user
  blockUserUrl: "/admin/user/deactivate-user/",
  getBlockUsersListUrl: "/admin/user/deactivated-users",
  unBlockUserUrl: "/admin/user/activate-user/",
  //block coach
  blockCoachUrl: "/admin/coach/deactivated-coach/",
  getBlockCoachesListUrl: "/admin/coach/deactivated-coaches",
  unBlockCoachUrl: "/admin/coach/activate-coach/",
  //update user
  updateUserUrl: "/admin/user/update-user/",
  //update coach
  updateCoachUrl: "/admin/coach//update-coach/",
  //add user
  addUserUrl: "/admin/user/add-user",
  //add coach
  addCoachUrl: "/admin/user/add-user",
  //journey
  getJourneysDataUrl: "/user/journey-list",
  // activities
  getUserActivitiesUrl: "/admin/activity/get-allActivities",
  selectedActivityDataOfUserUrl: "/admin/activity/get-activity/",
  updateActivityUrl: "/admin/activity/update-activity/",
  createActivityUrl: "/activity/create",
  getActivityUrl: "/admin/activity/get-activity/",
  deleteActivityByIdUrl: "/admin/activity/delete-activity/",
  // user goals
  getUserGoalsUrl: "/admin/activity/user-goals/",
  // Questions
  addQuestionUrl: "/admin/question/add-newQuestion",
  allQuestionsUrl: "/admin/question/all-questions",
  getMatrixUrl: "/admin/question/matrix",
  getQuestionByIdUrl: "/admin/question/",
  deleteQuestionUrl: "/admin/question/",
  updateQuestionUrl: "/admin/question/",
  getQuestionsPositionsUrl: "/admin/question/questions-positionList",
  // organization
  getAllOrganizationsUrl: "/admin/organization/get-allOrganizations",
  addOrganizationUrl: "/admin/organization/create",
  blockOrganizationUrl: "/admin/organization/deactivate-organization/",
  getBlockOrganizationsListUrl: "/admin/organization/deactivated-organizations",
  unBlockOrganizationUrl: "/admin/organization/activate-organization/",
  updateOrganizationUrl: "/admin/organization/update-organization/",
  //upload content
  uploadFileUrl: "/admin/content/upload-file",
  getContentUrl: "/admin/content/contentById/",
  uploadContentUrl: "/admin/content/upload-content",
  getDailyContentUrl: "/content",
  contentListByPlanUrl: "/admin/content/list",
  uploadCsvUrl: "/admin/content/upload-csv",
  deleteContentUrl: "/admin/content/delete/",
  updateContentUrl: "/admin/content/update/",
  //GOALS
  allGoalsUrl: "/goals/all",
  //plans
  getAllPlansUrl: "/admin/plan/all",
  getFilteredPlansUrl: "/admin/plan/filtered-plans",
  getAllFreePlansUrl: "/admin/plan/free",
  addPlanUrl: "/admin/plan/create",
  deletePlanUrl: "/admin/plan/deactivate/",
  getPlanById: "/admin/plan/",
  updatePlanUrl: "/admin/plan/update/",
  planDayListUrl: "/admin/plan/day-list/",
  // reports
  getOrganizationReportUrl: "/admin/organization/organization-report/",
  getCoachReportUrl: "/admin/coach/engagments-rate",
  getUsersReportUrl: "/admin/organization/get-users-report",
  getRangeStreaksReportUrl: "/admin/coach/range-streaks-report",
  //feedbacks
  getFeedbacksUrl: "/admin/user/feedback-list",
  // streaks 
  recoverStreakUrl: "/admin/user/recover-streak",
  // contests 
  getAllContestsUrl: "/admin/contest/contests",
  getOrganizationsContestsUrl: "/admin/contest/organizations-contests",
  createContestUrl: "/admin/contest/create-contest",
  updateContestUrl: "/admin/contest/update-contest",
  deleteContestUrl: "/admin/contest/delete-contest",
};

export default authURLs;
