import React, { useEffect, useState } from "react";
import {
  Button,
  Dropdown,
  Form,
  Modal,
  NavLink,
  Spinner,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import imageCompression from "browser-image-compression";
import { toastify } from "../../components/Toast";
import {
  addOrganization,
  blockOrganization,
  getAllOrganizations,
  getBlockListOfOrganizations,
  unBlockOrganization,
  updateOrganization,
} from "../../redux/actions/authAction";

const ManageOrganizations = () => {
  const dispatch = useDispatch();
  const [newOrganizationName, setNewOrganizationName] = useState("");
  const [newOrganizationCode, setNewOrganizationCode] = useState("");
  const [newOrganizationPhoneNumber, setNewOrganizationPhoneNumber] =
    useState("");
  const [newOrganizationEmail, setNewOrganizationEmail] = useState("");
  const [addOrganizationModal, setAddOrganizationModal] = useState(false);
  const [editOrganizationModal, setEditOrganizationModal] = useState(false);
  const [blockOrganizationModal, setBlockOrganizationModal] = useState(false);
  const [newOrganizationLogo, setNewOrganizationLogo] = useState("");
  const [newSmsEnabled, setNewSmsEnabled] = useState(false);
  const [loading, setLoading] = useState(false);

  const [showImage, setShowImage] = useState(null);
  const [isLogo, setIsLogo] = useState(false);
  //edit
  const [editOrganizationName, setEditOrganizationName] = useState("");
  const [editOrganizationCode, setEditOrganizationCode] = useState("");
  const [editOrganizationPhoneNumber, setEditOrganizationPhoneNumber] =
    useState("");
  const [editOrganizationEmail, setEditOrganizationEmail] = useState("");
  const [editOrganizationMessage, setEditOrganizationMessage] = useState(" ");
  const [newMob, setNewMob] = useState("");
  const [editOrganizationId, setEditOrganizationId] = useState("");
  const [editOrganizationLogo, setEditOrganizationLogo] = useState("");
  const [editSmsEnabled, setEditSmsEnabled] = useState(false);

  const [showImageEdit, setShowImageEdit] = useState(null);
  const [isLogoEdit, setIsLogoEdit] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getAllOrganizations());
  }, []);
  useEffect(() => {
    if (newOrganizationPhoneNumber.startsWith("92")) {
      setNewMob("+" + newOrganizationPhoneNumber);
    } else {
      setNewMob("+1" + newOrganizationPhoneNumber);
    }
  }, [newOrganizationPhoneNumber]);
  const { allOrganizations, blockOrganizationslist } = useSelector(
    (state) => state.getOrganizationData
  );

  const onAddOrganizationModalClose = () => {
    setAddOrganizationModal(false);
    setNewOrganizationEmail("");
    setNewOrganizationPhoneNumber("");
    setNewOrganizationName("");
    setNewOrganizationCode("");
    setIsLogo(false);
    setShowImage("/img/dummyLogo.png");
  };
  const onAddOrganizationhandler = () => {
    if (newOrganizationName && newOrganizationPhoneNumber) {
      setLoading(true);
      let formData = new FormData();
      if (isLogo) {
        formData.append("image", newOrganizationLogo);
      }
      formData.append("organizationName", newOrganizationName);
      formData.append("orgAccessCode", newOrganizationCode);
      formData.append("email", newOrganizationEmail);
      formData.append("isOrganization", true);
      formData.append("isVerified", true);
      formData.append("phoneNumber", newMob);
      formData.append("organizationMessage", " ");
      formData.append("isSmsEnabled", newSmsEnabled);
      dispatch(addOrganization(formData, callBackForAdd));
    } else {
      toastify("error", "Name and Phone number are compulsory");
    }
  };
  const callBackForAdd = (res) => {
    if (res) {
      setAddOrganizationModal(false);
      dispatch(getAllOrganizations());
      setNewOrganizationEmail("");
      setNewOrganizationPhoneNumber("");
      setShowImage("/img/dummyLogo.png");
      dispatch(getAllOrganizations());
      setLoading(false);
      setNewSmsEnabled(false);
      setNewOrganizationName("");
      setNewOrganizationCode("");
    }
  };
  const manageContestbtn = (data) => {
    navigate("/Manage-contests", { state: { orgData: data } });

  };
  const blockOrganizationHandler = (data) => {
    dispatch(blockOrganization(data._id, callbackForAllUpdate));
  };
  const callbackForAllUpdate = () => {
    dispatch(getAllOrganizations());
  };
  const blockedOrganizationHandler = () => {
    setBlockOrganizationModal(true);
    dispatch(getBlockListOfOrganizations());
  };
  const unBlockOrganizationhandler = (data) => {
    dispatch(unBlockOrganization(data._id, callbackForUnBlock));
  };
  const callbackForUnBlock = () => {
    setBlockOrganizationModal(false);
    dispatch(getAllOrganizations());
  };
  const onEditOrganizationModalClose = () => {
    setEditOrganizationModal(false);
    setIsLogoEdit(false);
  };
  const editOrganizationbtn = (data) => {
    setEditOrganizationModal(true);
    setEditOrganizationId(data._id);
    setEditOrganizationName(data?.organizationName);
    setEditOrganizationCode(data?.orgAccessCode);
    setEditOrganizationPhoneNumber(data?.phoneNumber);
    setEditOrganizationEmail(data?.email);
    setEditOrganizationMessage(data?.organizationMessage ? data.organizationMessage : " ");
    setEditOrganizationLogo(
      data?.organizationLogo ? data?.organizationLogo : "/img/dummyLogo.png"
    );
    setShowImageEdit(
      data?.organizationLogo ? data?.organizationLogo : "/img/dummyLogo.png"
    );
    setEditSmsEnabled(data?.isSmsEnabled);
  };
  const onEditOrganizationhandler = () => {
    let formData = new FormData();
    if (isLogoEdit) {
      setLoading(true);
      console.log("43433", editOrganizationLogo);

      formData.append("image", editOrganizationLogo);
    }
    formData.append("organizationName", editOrganizationName);
    formData.append("orgAccessCode", editOrganizationCode);
    formData.append("email", editOrganizationEmail);
    formData.append("phoneNumber", editOrganizationPhoneNumber);
    formData.append("organizationMessage", editOrganizationMessage !== "" ? editOrganizationMessage : " ");
    formData.append("isSmsEnabled", editSmsEnabled);
    dispatch(
      updateOrganization(editOrganizationId, formData, callbackForUpdate)
    );
  };
  const callbackForUpdate = () => {
    dispatch(getAllOrganizations());
    setEditOrganizationModal(false);
    setEditOrganizationLogo("");
    setLoading(false);
  };
  const onEditImageSelect = async (e) => {
    setIsLogoEdit(true);
    // const imageFile = e.target.files[0];
    // const options = { maxSizeMB: 1, maxWidthOrHeight: 800 };

    const fileSize = e.target.files?.item(0)?.size;
    if (fileSize) {
      const fileMb = fileSize / 1024 ** 2;

      if (fileMb > 5) {
        toastify("warning", "File size can not be more than 5 MB");
        return;
      }
    }

    // try {
    //   const compressedImage = await imageCompression(imageFile, options);
    //   setEditOrganizationLogo(compressedImage);
    //   setShowImageEdit(URL.createObjectURL(compressedImage));
    // } catch (error) {
    //   console.log(error);
    // }

    setEditOrganizationLogo(e.target.files[0]);
    setShowImageEdit(URL.createObjectURL(e.target.files[0]));
  };
  const onImageSelect = async (e) => {
    setIsLogo(true);
    // const imageFile = e.target.files[0];
    // const options = { maxSizeMB: 1, maxWidthOrHeight: 800 };

    const fileSize = e.target.files?.item(0)?.size;
    if (fileSize) {
      const fileMb = fileSize / 1024 ** 2;

      if (fileMb > 5) {
        toastify("warning", "File size can not be more than 5 MB");
        return;
      }
    }

    // try {
    //   const compressedImage = await imageCompression(imageFile, options);
    //   setNewOrganizationLogo(compressedImage);
    //   setShowImage(URL.createObjectURL(compressedImage));
    // } catch (error) {
    //   console.log(error);
    // }
    setNewOrganizationLogo(e.target.files[0]);
    setShowImage(URL.createObjectURL(e.target.files[0]));
  };
  return (
    <>
      {/* ADD ORGANIZATION MODAL */}
      <>
        <Modal
          backdrop="static"
          className="p-0"
          keyboard={false}
          data-dismiss="modal"
          show={addOrganizationModal}
          onHide={onAddOrganizationModalClose}
          centered
        >
          <Modal.Header className="modal-header-workout" closeButton>
            <Modal.Title>Add organization</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <>
              <Form>
                <div className="edit-profile-img">
                  {showImage !== null ? (
                    <img
                      className=" user-profile-img"
                      src={showImage}
                      alt="img1"
                    />
                  ) : (
                    <>
                      {newOrganizationLogo ? (
                        <img
                          className="user-profile-img"
                          src={newOrganizationLogo}
                          alt="img"
                        />
                      ) : (
                        <img
                          className="user-profile-img"
                          src="/img/dummyLogo.png"
                          alt="img"
                        />
                      )}
                    </>
                  )}

                  <div className="image-file">
                    <label htmlFor="file-input">
                      <img
                        className="edit-profile-img-icon"
                        src="/img/edit.png"
                        alt="img"
                      />
                    </label>
                    <input
                      type="file"
                      id="file-input"
                      onChange={onImageSelect}
                      accept="image/png, image/gif, image/jpeg, image/jpg"
                    />
                  </div>
                </div>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Name"
                    onChange={(e) => setNewOrganizationName(e.target.value)}
                    value={newOrganizationName}
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Code</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Code"
                    onChange={(e) => setNewOrganizationCode(e.target.value)}
                    value={newOrganizationCode}
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Phone number </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Phone number"
                    onChange={(e) =>
                      setNewOrganizationPhoneNumber(e.target.value)
                    }
                    value={newOrganizationPhoneNumber}
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Email"
                    onChange={(e) => setNewOrganizationEmail(e.target.value)}
                    value={newOrganizationEmail}
                  />
                </Form.Group>
              </Form>
            </>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={onAddOrganizationModalClose}>
              Cancel
            </Button>
            {loading ? (
              <Button variant="primary">
                <Spinner
                  className="ps-2"
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              </Button>
            ) : (
              <Button variant="primary" onClick={onAddOrganizationhandler}>
                Save
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      </>
      {/* EDIT ORGANIZATION MODAL */}
      <>
        <Modal
          backdrop="static"
          className="p-0"
          keyboard={false}
          data-dismiss="modal"
          show={editOrganizationModal}
          onHide={onEditOrganizationModalClose}
          centered
        >
          <Modal.Header className="modal-header-workout" closeButton>
            <Modal.Title>Edit organization</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <>
              <Form>
                <div className="edit-profile-img">
                  {showImageEdit !== null ? (
                    <img
                      className=" user-profile-img"
                      src={showImageEdit}
                      alt="img1"
                    />
                  ) : (
                    <>
                      {editOrganizationLogo ? (
                        <img
                          className="user-profile-img"
                          src={editOrganizationLogo}
                          alt="img"
                        />
                      ) : (
                        <img
                          className="user-profile-img"
                          src="/img/dummyLogo.png"
                          alt="img"
                        />
                      )}
                    </>
                  )}

                  <div className="image-file">
                    <label htmlFor="file-input">
                      <img
                        className="edit-profile-img-icon"
                        src="/img/edit.png"
                        alt="img"
                      />
                    </label>
                    <input
                      type="file"
                      id="file-input"
                      onChange={onEditImageSelect}
                      accept="image/png, image/gif, image/jpeg, image/jpg"
                    />
                  </div>
                </div>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="name"
                    onChange={(e) => setEditOrganizationName(e.target.value)}
                    value={editOrganizationName}
                  />
                </Form.Group>

                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Code</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="code"
                    onChange={(e) => setEditOrganizationCode(e.target.value)}
                    value={editOrganizationCode}
                  />
                </Form.Group>

                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Phone number</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="phone number"
                    onChange={(e) =>
                      setEditOrganizationPhoneNumber(e.target.value)
                    }
                    value={editOrganizationPhoneNumber}
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="email"
                    onChange={(e) => setEditOrganizationEmail(e.target.value)}
                    value={editOrganizationEmail}
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1">
                  <Form.Label>Message</Form.Label>
                  <Form.Control 
                    as="textarea"
                    rows={5}
                    onChange={(e) => setEditOrganizationMessage(e.target.value)}
                    value={editOrganizationMessage}
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1">
                  <Form.Label>SMS Notifcations</Form.Label>
                  <Form.Switch checked={editSmsEnabled} label={editSmsEnabled ? "ON" : "OFF"} onChange={() => setEditSmsEnabled(prev => !prev)} />
                </Form.Group>
              </Form>
            </>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={onEditOrganizationModalClose}>
              Cancel
            </Button>
            {loading ? (
              <Button variant="primary">
                <Spinner
                  className="ps-2"
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              </Button>
            ) : (
              <Button variant="primary" onClick={onEditOrganizationhandler}>
                Save
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      </>
      {/* BLOCK USER LIST MODAL */}
      <>
        <Modal
          backdrop="static"
          className="p-0"
          size="lg"
          keyboard={false}
          data-dismiss="modal"
          show={blockOrganizationModal}
          onHide={() => setBlockOrganizationModal(false)}
          centered
        >
          <Modal.Header className="modal-header-workout" closeButton>
            <Modal.Title>Blocked list of Organization</Modal.Title>
          </Modal.Header>
          <Modal.Body className="modal-body-unblock-list">
            <>
              {blockOrganizationslist?.data?.length ? (
                <>
                  <table>
                    <thead>
                      <tr
                        className="header-row"
                        style={{ margin: "10px", height: "50px" }}
                      >
                        <th
                          style={{
                            width: "50px",
                            borderTopLeftRadius: "8px",
                            borderBottomLeftRadius: "8px",
                          }}
                        ></th>
                        <th
                          style={{
                            width: "400px",
                            paddingLeft: "6px",
                          }}
                        >
                          Organization name
                        </th>

                        <th
                          className="block-user-email"
                          style={{
                            width: "400px",
                          }}
                        >
                          Email
                        </th>
                        <th
                          style={{
                            borderTopRightRadius: "8px",
                            borderBottomRightRadius: "8px",
                          }}
                        ></th>
                      </tr>
                    </thead>

                    <tbody>
                      {blockOrganizationslist?.data?.map((data, index) => {
                        return (
                          <tr
                            className="whole-row-client"
                            key={index}
                            style={{
                              borderBottom: "1px solid #f1f5f9",
                            }}
                          >
                            <td
                              style={{
                                paddingLeft: "35px",
                              }}
                            >
                              <div className="form-check ">
                                <input
                                  className="form-check-input client-checkbox"
                                  type="checkbox"
                                  value=""
                                  id="flexCheckChecked"
                                />
                              </div>
                            </td>
                            <td className="pic-name-in-table">
                              <div className="client-img-in-table ms-3 pt-1">
                                <img
                                  src={
                                    data?.organizationLogo
                                      ? data?.organizationLogo
                                      : "/img/dummyLogo.png"
                                  }
                                  className="coach-clients-data-img "
                                  alt="EQWell logo"
                                />
                                {/* )} */}
                              </div>
                              <div
                                style={{
                                  paddingTop: "15px",
                                  wordBreak: "break-word",
                                }}
                              >
                                {data?.organizationName}
                              </div>
                            </td>

                            <td
                              style={{
                                color: "#1b46b4",
                                width: "500px",
                                wordBreak: "break-word",
                              }}
                            >
                              {data?.email}
                            </td>
                            <td
                              style={{
                                width: "133px",
                              }}
                            >
                              <div className="clients-data-btn">
                                <button
                                  className="btn-unblock-user"
                                  onClick={() =>
                                    unBlockOrganizationhandler(data)
                                  }
                                >
                                  Unblock
                                </button>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </>
              ) : (
                <h5>No organizations blocked yet.</h5>
              )}
            </>
          </Modal.Body>
        </Modal>
      </>
      <>
        {" "}
        <div className="manage-user">
          <div className="row mb-3 pt-5 pl-5">
            <div className="col-md-6">
              <h1 className="healthKitUpdateHeaderFont d-flex">
                Manage Organizations
              </h1>
            </div>
            <div
              className="col-md-6 d-flex justify-content-end"
              style={{ paddingRight: "65px" }}
            >
              <div>
                <button
                  className="btn-coach me-4"
                  onClick={() => setAddOrganizationModal(true)}
                >
                  Add organization
                </button>
              </div>
              <div>
                <button
                  className="btn-coach btn-block me-5"
                  onClick={blockedOrganizationHandler}
                >
                  Blocked organization
                </button>
              </div>
            </div>
          </div>
          <>
            <div
              className="coach-clients-page "
              style={{ padding: "2% 3% 0% 3%" }}
            >
              <>
                <div className="clients-page-main-container">
                  {
                    // coachClientsData?.data?.length !== 0
                    allOrganizations?.data?.length ? (
                      <>
                        <table>
                          <thead>
                            <tr
                              className="header-row"
                              style={{ margin: "10px", height: "50px" }}
                            >
                              {/* <th
                                style={{
                                  width: "50px",
                                  borderTopLeftRadius: "8px",
                                  borderBottomLeftRadius: "8px",
                                }}
                              ></th> */}
                              <th
                                style={{
                                  width: "550px",
                                  paddingLeft: "30px",
                                  textAlign: "left",
                                }}
                              >
                                Name
                              </th>
                              <th
                                style={{
                                  width: "400px",
                                  textAlign: "left",
                                }}
                              >
                                Code
                              </th>
                              <th
                                style={{
                                  width: "400px",
                                  textAlign: "left",
                                }}
                              >
                                Phone number
                              </th>
                              <th
                                style={{
                                  width: "400px",
                                  textAlign: "left",
                                }}
                              >
                                Email
                              </th>
                              <th
                                style={{
                                  width: "400px",
                                  textAlign: "left",
                                }}
                              >
                                Message
                              </th>
                              <th
                                style={{
                                  borderTopRightRadius: "8px",
                                  borderBottomRightRadius: "8px",
                                }}
                              ></th>
                            </tr>
                          </thead>

                          <tbody>
                            {allOrganizations?.data?.map((data, index) => {
                              return (
                                <tr
                                  className="whole-row-client"
                                  key={index}
                                  style={{
                                    borderBottom: "1px solid #f1f5f9",
                                  }}
                                >
                                  {/* <td
                                    style={{
                                      paddingLeft: "35px",
                                    }}
                                  >
                                    <div className="form-check ">
                                      <input
                                        className="form-check-input client-checkbox"
                                        type="checkbox"
                                        value=""
                                        id="flexCheckChecked"
                                      />
                                    </div>
                                  </td> */}
                                  <td className="pic-name-in-table">
                                    <div className="client-img-in-table ms-3 pt-1">
                                      {data?.organizationLogo ? (
                                        <img
                                          src={data?.organizationLogo}
                                          className="coach-clients-data-img "
                                          alt="EQWell logo"
                                        />
                                      ) : (
                                        <img
                                          src="/img/dummyLogo.png"
                                          className="coach-clients-data-img "
                                          alt="EQWell logo"
                                        />
                                      )}
                                    </div>
                                    <div
                                      style={{
                                        paddingTop: "15px",
                                        wordBreak: "break-word",
                                      }}
                                    >
                                      {data?.organizationName}
                                    </div>
                                  </td>

                                  <td>
                                    <div
                                      className="d-grid gap-2 d-md-block"
                                      style={{
                                        wordBreak: "break-word",
                                        textAlign: "left",
                                      }}
                                    >
                                      {data?.orgAccessCode}
                                    </div>
                                  </td>

                                  <td
                                    style={{
                                      paddingLeft: "12px",
                                      color: "#1b46b4",
                                      width: "160px",
                                      textAlign: "left",
                                      wordBreak: "break-word",
                                    }}
                                  >
                                    {data?.phoneNumber}
                                  </td>
                                  <td
                                    style={{
                                      paddingLeft: "12px",
                                      color: "#1b46b4",
                                      width: "500px",
                                      textAlign: "left",
                                      wordBreak: "break-word",
                                    }}
                                  >
                                    {data?.email}
                                  </td>
                                  <td
                                    style={{
                                      paddingLeft: "12px",
                                      color: "#1b46b4",
                                      width: "500px",
                                      textAlign: "left",
                                      wordBreak: "break-word",
                                    }}
                                  >
                                    {data?.organizationMessage && data.organizationMessage}
                                  </td>
                                  <td
                                    style={{
                                      width: "133px",
                                    }}
                                  >
                                    <div className="clients-data-btn">
                                      <Dropdown>
                                        <Dropdown.Toggle id="toggle-btn-clients">
                                          <img
                                            src="/img/client-detail-btn.png"
                                            alt="btn-img"
                                            style={{ backgroundColor: "white" }}
                                          />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                          <Dropdown.Item className="create-activity-font" onClick={() => editOrganizationbtn(data)}>
                                            Edit
                                            {/* <NavLink
                                              className="create-activity-font"
                                              onClick={() => {
                                                editOrganizationbtn(data);
                                              }}
                                            >
                                              Edit
                                            </NavLink>
                                            <NavLink
                                              className="create-activity-font"
                                              onClick={() => {
                                                blockOrganizationHandler(data);
                                              }}
                                            >
                                              Block
                                            </NavLink> */}
                                          </Dropdown.Item>
                                          <Dropdown.Item className="create-activity-font" onClick={() => blockOrganizationHandler(data)}>Block</Dropdown.Item>
                                          <NavLink
                                              className="create-activity-font"
                                              onClick={() => {
                                                manageContestbtn(data);
                                              }}
                                            >
                                              Manage Contest
                                            </NavLink>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </>
                    ) : (
                      <div className="No-data-statement">
                        <h3>No organization yet</h3>
                      </div>
                    )
                  }
                </div>
              </>
            </div>
          </>
        </div>
      </>
      <ToastContainer />
    </>
  );
};

export default ManageOrganizations;
